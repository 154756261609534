@use "./mixins/display";
@use "./mixins/basics";
@use "./mixins/utils";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
}

a {
  text-decoration: none;
  color: #000;

  &:hover {
    color: black;
    opacity: 0.7;
  }
}

p {
  font-size: 0.88rem;

  &.small {
    font-size: 0.85rem;
  }
}

.main-header-extra-class {
  //To hide loading icon initial in header
  & span.ant-btn-loading-icon {
    display: none;
  }
}

/* Default elements */
/* buttons */

button {
  padding: 7px 20px;
  cursor: pointer;

  &.back {
    background-color: transparent;
    border: 1px solid rgb(107, 41, 107);
    border-radius: 10px;
    font-size: 0.8rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

/* Inputs */
input {
  border: none;
  background-color: lighten(basics.$primary-color, 45);
  padding: 15px 20px;

  &:focus {
    outline: none;
  }
}

/* Common styles */
.full-width {
  width: 100%;
}

/* ClassName with pattern: display-justify-content-align-items */
.grid-c {
  display: grid;
  place-items: center;
}

.flex {
  @include display.display-type(flex);
}

.circlebox {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  margin-bottom: 24px;
}

//Override ant design default styles

//Manage heigh of form label
.ant-form-item-label > label {
  height: auto !important;
}

.btnContainer {
  .ant-btn-primary {
    background-color: #1890ff;
  }

  .ant-btn-success {
    background-color: #52c41a;
  }

  .ant-btn-yellow {
    background-color: #fadb14;
  }

  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }

  .ant-switch-active {
    background-color: #1890ff;
  }
}
