@use '../../../Style/mixins/basics';

.mainLayoutContainer {
    /* using height of header */
    height: calc(100% - basics.$header-height);
    width: 100%;
    background-color: rgb(127, 255, 0, 0.2);
    backdrop-filter: blur(10px);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;

    @include basics.media-query(m) {
        height: calc(100% - 55.45px);
    }
}