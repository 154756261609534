@use '../../../../Style/mixins/basics';

.mainHeader {
    height: basics.$header-height;
    background-color: #fff;
}

.headerLogo {
    width: 11rem;

    @include basics.media-query(m) {
        width: 9rem;
    }
}

.headerTitle {
    font-size: 1.4rem;

    @include basics.media-query(m) {
        font-size: 1.2rem;
    }
}