.creditData {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Divider {
  & > span {
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    color: rgb(101, 98, 98);
  }

  &::after,
  &::before {
    border-top: 2px solid rgb(191, 189, 189) !important;
  }
}

.otherData {
  font-size: 1.1rem;
  font-weight: 450;
  height: fit-content;
  color: rgb(65, 64, 64);
}

.credits {
  font-weight: normal;
  font-size: 5.5rem;
  color: rgb(149, 149, 149);
  line-height: 100px;
}
