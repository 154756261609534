.profileFormContainer {
  background-color: #fff;
  padding: 20px 30px;

  &.readOnly {
    :global {
      & .ant-form-item-label {
        & label::before {
          content: "" !important;
        }
      }
    }
  }

  & input:disabled {
    background-color: #fff;
    color: #000;
    cursor: default;
    padding: 0;
    border: none;
    font-size: 1rem;
    font-style: italic;
  }

  :global {
    & .ant-form-item-label {
      display: flex;
      & label {
        font-weight: 500;
        color: grey !important;
      }
    }

    // To make select and date background white
    &
      .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    & .ant-picker.ant-picker-disabled {
      background-color: #fff;
      color: #000;
      cursor: default;
      padding: 0;
      border: none;
      font-size: 1rem;
      font-style: italic;
    }
  }
}

.pageTitle {
  font-size: 1.2rem;
}
