.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.containFully {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
  }
}

.text {
  font-weight: 550;
  letter-spacing: 0.1rem;
  color: rgb(83, 81, 81);
}
