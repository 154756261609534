.container {
  & button {
    padding: 5px 20px;
    height: fit-content !important;
    width: 80px;
  }

  :global {
    & .ant-btn-loading-icon {
      display: none;
    }
  }
}
