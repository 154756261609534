@use "../../Style/mixins/display";
@use "../../Style/mixins/utils";

.modalWrapper {
  width: 100%;

  .titleWrapper {
    font-size: 25px;
    @include display.display-type(flex, center);
    font-weight: 600;
    margin-bottom: 10px;
  }
  :global {
    .ant-modal-content {
      border-radius: 20px;
    }
  }
}
